<template>
  <q-avatar v-if="photo" :size="size">
    <q-img :src="photo" />
  </q-avatar>
  <q-avatar v-else :size="size" :color="'teal'">
    <span class="text-white">{{ first_latter }}</span>
  </q-avatar>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useGetters } = createNamespacedHelpers("auth");

export default {
  name: "Avatar",

  props: {
    size: {
      type: String,
      default: "72px",
    },
  },

  setup() {
    const { first_latter, photo } = useGetters({
      photo: "getPhoto",
      first_latter: "getFirstLatter",
    });

    return {
      photo,
      first_latter,
    };
  },
};
</script>
