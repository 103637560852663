<template>
  <q-dialog persistent>
    <q-card style="width: 520px">
      <q-bar class="bg-black">
        <q-space />
        <q-btn
          dense
          flat
          color="white"
          icon="close"
          v-close-popup
          v-if="step != 2"
        >
          <q-tooltip>{{ $t("global.close") }}</q-tooltip>
        </q-btn>
      </q-bar>
      <q-card-section v-if="step == 0">
        <div class="q-pr-md q-pl-md text-center">
          <img :src="data.logo_foto_link" style="width: 220px" />
        </div>
        <div class="terms-content scroll" v-if="data.biography">
          <quill-editor
            :value="{ ops: JSON.parse(data.biography) }"
            :disabled="state.disabled"
            :options="state.editorOption"
          />
        </div>
        <div
          v-else
          class="text-justify q-mb-md q-pa-md"
          v-html="$t('dashboard.flip.subscribe_msg', { name: data.nome })"
        />
        <div
          class="bg-grey-4 q-pt-lg q-pb-md q-pl-md q-pr-md q-mt-md default-rounded"
          v-if="isAnAssociableCompany()"
        >
          <div class="row">
            <q-img
              width="60px"
              style="margin-top: -14px"
              class="q-ml-auto q-mr-auto q-mb-sm"
              src="https://cdn-icons-png.flaticon.com/512/1069/1069839.png"
            />
          </div>
          <p
            class="text-justify"
            v-html="
              $t('new_dashboard.u_already_have_an_account', { name: data.nome })
            "
          />
          <div v-if="getAnAssociableCompany().need_authorization_to_associate">
            <div>
              <p
                class="text-weight-bold"
                v-html="$t('new_dashboard.code_will_be_sent')"
              />
            </div>
            <p
              style="font-size: 14pt"
              class="text-center bg-white q-pa-xs default-rounded text-blue"
              v-html="getAnAssociableCompany().email_principal"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-section v-if="step == 1">
        <div class="q-pr-md q-pl-md text-center">
          <img :src="data.logo_foto_link" />
        </div>
        <div
          class="bg-grey-4 text-justify q-pt-lg q-pb-md q-pl-md q-pr-md default-rounded"
          v-html="
            $t('new_dashboard.code_was_sent', {
              email: getAnAssociableCompany().email_principal,
            })
          "
        />
        <get-code-component @getCode="setCode($event)" />
        <q-card-section class="q-pt-none q-mt-md q-mb-md">
          <div
            class="text-justify"
            :class="{
              'text-grey-8': inTime > 0,
              'text-primary': inTime == 0,
            }"
          >
            <span
              @click="resend(getAnAssociableCompany().email_principal)"
              :style="`cursor: ${
                inTime > 0 ? 'wait' : 'pointer'
              }; font-size: 0.9em;`"
              >Não recebeu o código? Clique aqui para reenviar.</span
            >
            {{ inTime ? `(${inTime}s)` : "" }}
          </div>
        </q-card-section>
      </q-card-section>

      <q-card-section v-if="step == 2">
        <div class="q-pr-md q-pl-md text-center">
          <img :src="data.logo_foto_link" style="width: 220px" />
        </div>
        <div class="q-mt-md q-mb-md q-pa-md bg-grey-3 text-justify">
          Precisamos de alguns documentos seus para completar seu cadastro.
        </div>
        <template v-if="!in_request_send_docs">
          <SendDocumentsComponent :uploadToAnotherCompany="true" />
        </template>
        <div class="text-center q-mt-lg" v-else>
          <q-spinner color="grey-4" size="10em" :thickness="10" />
        </div>
      </q-card-section>

      <q-card-actions align="right" v-if="showActions">
        <template v-if="step != 2">
          <q-btn
            color="black"
            :loading="in_request"
            :label="$t('global.confirm')"
            :disabled="code.length < 6 && step == 1"
            @click="
              step == 1
                ? subscribePartnerWithValidation()
                : advanceProcess(data.slug)
            "
          />
          <q-btn
            flat
            id="closeModal"
            color="black"
            v-close-popup
            :label="$t('global.cancel')"
          />
        </template>
        <template v-else>
          <q-btn
            color="black"
            :label="$t('global.finish')"
            :disabled="formatOptions(docTypes, getUserDocuments).length"
            @click="handleAndCloseForm()"
          />
        </template>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import GetCodeComponent from "@/modules/main/pages/account/children/verification-components/GetCodeComponent.vue";
import SendDocumentsComponent from "@/components/SendDocuments/SendDocumentsComponent.vue";
import { setCustomJwt } from "@/components/SendDocuments/setup/uploadWithCustomJwt";
import { formatOptions } from "../../../../../components/SendDocuments/setup";
import { useActions, useGetters } from "vuex-composition-helpers";
import quillEditor from "@/modules/main/components/quillEditor";
import { defineProps, reactive, ref, defineEmits } from "vue";
import { notifySuccess } from "@/shared/helpers/notify";
import { useI18n } from "vue-i18n";

const emits = defineEmits(["closeThisModal"]);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  showActions: {
    type: Boolean,
    default: true,
  },
  hasDocStep: {
    type: Boolean,
    default: false,
  },
  show: {
    type: Boolean,
    default: true,
  },
  color: {
    type: String,
    default: "primary",
  },
  label: {
    type: String,
    default: "Subscribe",
  },
});

/**
 * step == 1 -> Code confirmation step
 * step == 0 -> Subscription don't need confirmation
 * step == 2 -> Send documents
 */
const step = ref(0);

const { t } = useI18n();
const code = ref("");
const inTime = ref(0);
const secret = ref("");
const jwtToken = ref("");
const in_request = ref(false);
const in_request_send_docs = ref(true);

const state = reactive({
  editorOption: {
    readOnly: true,
    theme: "snow",
    modules: {
      toolbar: false,
    },
  },
  disabled: true,
});

const {
  subscribe,
  getJWTTokenToCompany,
  handleDashboardServices,
  fetchAssociateWithoutValidation,
  fetchAssociateWithValidation,
  fetchPartnerChallenges,
  fetchSendChallengeExt,
  fetchDocDataToCustomJwt,
} = useActions({
  subscribe: "home/fetchSubscribePartner",
  getJWTTokenToCompany: "auth/getJWTTokenToCompany",
  handleDashboardServices: "home/handleDashboardServices",
  fetchAssociateWithoutValidation: "home/fetchAssociateWithoutValidation",
  fetchAssociateWithValidation: "home/fetchAssociateWithValidation",
  fetchPartnerChallenges: "account/fetchPartnerChallenges",
  fetchSendChallengeExt: "account/fetchSendChallengeExt",
  fetchDocDataToCustomJwt: "account/fetchDocDataToCustomJwt",
});

const {
  getNotAssociatedAccs,
  getPartnerChallengesHash,
  docTypes,
  getUserDocuments,
} = useGetters({
  getNotAssociatedAccs: "home/getNotAssociatedAccs",
  getPartnerChallengesHash: "account/getPartnerChallengesHash",
  docTypes: "account/getDocTypes",
  getUserDocuments: "account/getUserDocuments",
});

function isAnAssociableCompany() {
  return getNotAssociatedAccs.value.some(
    (company) => company.parent.slug === props.data.slug
  );
}

function getAnAssociableCompany() {
  return getNotAssociatedAccs.value.find(
    (company) => company.parent.slug === props.data.slug
  );
}

function advanceProcess(parent_slug) {
  let parentAssociable = getAnAssociableCompany();

  /**
   * In case the company is not associable, just subscribe
   */
  if (!isAnAssociableCompany()) {
    subscribePartner(parent_slug);
    return;
  }

  in_request.value = true;

  /**
   * If the company is associable
   */
  if (parentAssociable.need_authorization_to_associate) {
    fetchPartnerChallenges()
      .then(() => sendEmailChallenge(parentAssociable.email_principal))
      .finally(() => (in_request.value = false));
  } else {
    fetchAssociateWithoutValidation({
      partner_slug: parentAssociable.slug,
      parent_slug,
    })
      .then(() => successSubscription())
      .finally(() => (in_request.value = false));
  }
}

function subscribePartner(slug_parent) {
  in_request.value = true;
  subscribe({ slug_parent, utm_source: "flipnet" })
    .then(() => successSubscription())
    .finally(() => (in_request.value = false));
}

function subscribePartnerWithValidation() {
  in_request.value = true;
  fetchAssociateWithValidation({
    partner_slug: getAnAssociableCompany().slug,
    parent_slug: getAnAssociableCompany().parent.slug,
    hash: getPartnerChallengesHash.value,
    secret: secret.value,
    code_confirmation: code.value,
  })
    .then(() => successSubscription())
    .finally(() => (in_request.value = false));
}

function sendEmailChallenge(email, isResend = false) {
  fetchSendChallengeExt({
    type: "email",
    value: email,
    hash: getPartnerChallengesHash.value,
  })
    .then((data) => {
      if (!isResend) step.value++;
      secret.value = data.secret_key;
    })
    .finally(() => (in_request.value = false));
}

const resend = (email) => {
  if (inTime.value > 0) return;

  inTime.value = 30;
  let intervalId = setInterval(() => {
    if (inTime.value > 0) {
      inTime.value--;
    } else clearInterval(intervalId);
  }, 1000);

  sendEmailChallenge(email, true);
};

async function successSubscription() {
  /**
   * Para realizar o cadastro de documentos, é primeiro gerado a assiciação do parceiro com a marca
   * e depois é necessário obter um token JWT dessa conta na marca cadastrada e realizar a subida
   * dos arquivos corretamente.
   */
  if (props.hasDocStep == false) {
    handleDashboardServices();
    notifySuccess(t("account.association_success"));
    closeData();
  } else {
    let token = await getJWTTokenToCompany({ slug: props.data.slug });
    setCustomJwt(token);
    fetchDocDataToCustomJwt().then(() => {
      step.value = 2;
      setTimeout(() => (in_request_send_docs.value = false), 1000);
    });
  }
}

const handleAndCloseForm = () => {
  handleDashboardServices();
  closeData();
};

const closeData = () => {
  step.value = 0;
  code.value = "";
  secret.value = "";
  jwtToken.value = "";
  emits("closeThisModal");
};

function setCode(e) {
  code.value = e;
}
</script>

<style lang="scss" scoped>
.terms-content {
  max-height: 90vh;
  max-width: 900px;
}
</style>
